<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="timeSheetsOutput"
      item-key="meta.id"
      multi-sort
      sort-by="billableDuration.start.timestamp"
      :items-per-page="25"
      :footer-props="footerProps"
      :search="search"
      groupBy="groupBy"
      class="elevation-2"
      max-height="800px auto"
      @click:row="routeDetails"
      no-data-text="Keine Stundenmeldungen vorhanden"
      no-results-text="Keine Stundenmeldungen gefunden"
    >
      <template v-slot:top>
        <v-card flat>
          <v-card-subtitle>
            <v-row>
              <v-col v-if="showAllTimeSheets" cols="12" sm="4" md="4">
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Nach Personen suchen"
                  :disabled="timeSheetsPersonFilter === 'own'"
                  hide-details="auto"
                  clearable
                  :dense="$vuetify.breakpoint.smAndDown"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="showAllTimeSheets"
                :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              >
                <span class="mr-4">Filter nach Typ</span>
                <v-chip-group
                  v-model="timeSheetsPersonFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle
                  </v-chip>
                  <v-chip
                    label
                    value="own"
                    class="transparent-background font-weight-medium"
                    >Eigene</v-chip
                  >
                </v-chip-group>
              </v-col>
              <v-col>
                <span class="mr-4">Filter nach Quelle</span>
                <v-chip-group
                  v-model="timeSheetsSourceFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle</v-chip
                  >
                  <v-chip
                    label
                    value="manual"
                    class="transparent-background font-weight-medium mr-2"
                    >Manuell</v-chip
                  >
                  <v-chip
                    label
                    value="dutyReport"
                    class="transparent-background font-weight-medium mr-2"
                    >Dienstbericht</v-chip
                  >
                </v-chip-group>
              </v-col>
              <v-col>
                <span class="mr-4">Filter nach Status</span>
                <v-chip-group
                  v-model="timeSheetsStateFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle</v-chip
                  >
                  <v-chip
                    label
                    value="toBeReviewed"
                    class="transparent-background font-weight-medium mr-2"
                    >Zu prüfen</v-chip
                  >
                  <v-chip
                    label
                    value="missingPayrollType"
                    class="transparent-background font-weight-medium mr-2"
                    >Fehlerhaft</v-chip
                  >
                </v-chip-group>
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
        <v-divider></v-divider>
      </template>

      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th :colspan="headers.length">
          <v-icon @click="toggle" class="mr-1"
            >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
          {{ stripPrefix(items[0].groupBy) }}
          <span class="font-weight-regular grey--text"
            >({{ getCountOfTimeSheetsInMonth(items[0].groupBy) }})</span
          >
        </th>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <time-sheet-type-chip :type="item.type" small> </time-sheet-type-chip>
      </template>
      <template v-slot:[`item.payrollType.title`]="{ item }">
        <v-chip v-if="item.payrollType.title" small outlined label>{{
          item.payrollType.title
        }}</v-chip>
      </template>
      <template v-slot:[`item.billableDuration.start.timestamp`]="{ item }">
        {{ item.billableDuration.start.formattedTimestamp }}
      </template>
      <template v-slot:[`item.billableDuration.end.timestamp`]="{ item }">
        {{ item.billableDuration.end.formattedTimestamp }}
      </template>
      <template #[`item.status`]="{ item }">
        <time-sheet-status-chip :status="item.status" small>
        </time-sheet-status-chip>
      </template>
      <template #[`item.actions`]="{ item }"
        ><v-btn
          v-if="showActionBtn"
          depressed
          @click="openTimeSheet(item, 'newTab')"
          small
          ><v-icon left small>mdi-open-in-app</v-icon>Öffnen</v-btn
        ><v-menu v-else offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon text v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="routeDetails(item)">
              <v-list-item-icon>
                <v-icon dense>mdi-open-in-app</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Stundenmeldung öffnen</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link @click="openTimeSheet(item, 'newTab')">
              <v-list-item-icon>
                <v-icon dense>mdi-open-in-new</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                >Stundenmeldung öffnen (in neuem Tab)</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import TimeSheetStatusChip from "@/components/accounting/time-sheets/TimeSheetStatusChip.vue";
import TimeSheetTypeChip from "@/components/accounting/time-sheets/TimeSheetTypeChip.vue";
import { ACCOUNTING_TIME_SHEETS_read_all } from "@/data/permission-types.js";
export default {
  name: "time-sheets-data-table",
  props: {
    timeSheets: {
      type: Array,
      required: true,
    },
    showActionBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    TimeSheetStatusChip,
    TimeSheetTypeChip,
  },
  data() {
    return {
      search: "",
      timeSheetsPersonFilter: "own",
      timeSheetsStateFilter: "active",
      timeSheetsSourceFilter: "manual",
      currentUserUid: auth.currentUser.uid,
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
    };
  },
  computed: {
    showAllTimeSheets() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${ACCOUNTING_TIME_SHEETS_read_all}`
      );
    },
    timeSheetsInput() {
      const output = this.timeSheets.map((timeSheet) => {
        return {
          ...timeSheet,
          groupBy: this.groupByDateText(
            timeSheet.billableDuration.start.timestamp.toDate()
          ),
        };
      });
      return output;
    },
    timeSheetsOutput() {
      return this.timeSheetsInput.filter((timeSheet) => {
        // Filter nach Person
        if (
          this.timeSheetsPersonFilter === "own" &&
          timeSheet.user.uid !== this.currentUserUid
        ) {
          return false;
        }
        return true;
      });
    },
    headers() {
      const headersArray = [];

      if (this.timeSheetsPersonFilter === "all") {
        headersArray.push({
          text: "Vorname",
          value: "user.firstName",
        });
        headersArray.push({
          text: "Nachname",
          value: "user.lastName",
        });
      }

      headersArray.push(
        { text: "Tätigkeit", value: "title" },
        {
          text: "Typ",
          value: "type",
          filter: (value) => {
            if (!this.timeSheetsSourceFilter) return true;
            if (this.timeSheetsSourceFilter === "all") return true;
            if (this.timeSheetsSourceFilter === "dutyReport") {
              return value === "dutyReport";
            }
            if (this.timeSheetsSourceFilter === "manual") {
              return value !== "dutyReport";
            }
          },
        },
        { text: "Startzeitpunkt", value: "billableDuration.start.timestamp" },
        { text: "Endzeitpunkt", value: "billableDuration.end.timestamp" },
        {
          text: "Status",
          value: "status",
          filter: (value) => {
            if (!this.timeSheetsStateFilter) return true;
            if (this.timeSheetsStateFilter === "all") return true;
            return value === this.timeSheetsStateFilter;
          },
        },
        { text: "Abrechnungsart", value: "payrollType.title" },
        {
          value: "actions",
          sortable: false,
        }
      );

      return headersArray;
    },
  },
  methods: {
    routeDetails(item) {
      if (!this.showActionBtn) {
        this.openTimeSheet(item);
      }
    },
    openTimeSheet(item, type = "default") {
      if (type === "newTab") {
        window.open(
          this.$router.resolve({
            name: "accounting-time-sheets-details",
            params: {
              organizationId: this.$route.params.organizationId,
              itemId: item.meta.id,
            },
          }).href,
          "_blank"
        );
        return;
      } else {
        this.$router.push({
          name: "accounting-time-sheets-details",
          params: {
            organizationId: this.$route.params.organizationId,
            itemId: item.meta.id,
          },
        });
      }
    },
    groupByDateText(date) {
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const monthLong = date.toLocaleString("de-DE", { month: "long" });
      return year + "-" + month + "_" + monthLong + " " + year;
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    getCountOfTimeSheetsInMonth(monthId) {
      return this.timeSheetsOutput.filter(
        (report) => report.groupBy === monthId
      ).length;
    },
  },
};
</script>
